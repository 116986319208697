<template>
  <div class="back-to-top">
    <a
      id="button"
      :class="isScroll ? 'show' : ''"
      @click.prevent="scrollToTop"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      isScroll: false,
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },

  methods: {
    handleScroll() {
      if (window.scrollY > 200) {
        this.isScroll = true
      } else {
        this.isScroll = false
      }
    },

    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
#button {
  display: inline-block;
  background-color: #e11c4e;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 6rem;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;

  &::after {
    content: "↑";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 44px;
    color: #fff;
  }

  &:hover {
    cursor: pointer;
    background-color: #333;
  }

  &:active {
    background-color: #555;
  }

  &.show {
  opacity: 1;
  visibility: visible;
}
}
</style>
