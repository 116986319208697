<template>
  <b-modal
    ref="modal-reason-reject-product-video"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal">
      <h3 class="font-medium text-primary font-20">
        {{ $t('productLinkPage.titleModalReasonRejectVideo') }}
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="info-reason-reject-video">
      <b-row>
        <b-col
          class="d-flex align-items-center"
          cols="7"
        >
          <div class="info-product-video">
            <p class="mb-0 mr-1">
              Video:
            </p>
            <p
              v-b-tooltip.v-light="videoInfo.videoURL"
              class="name-product-video"
              @click="openModalPlayVideo(videoInfo.videoURL)"
            >{{ getNameVideo(videoInfo.videoURL) }}</p>
          </div>
        </b-col>
        <b-col
          cols="5"
          class="pl-0"
        >
          <div class="status-product-video">
            <p class="mb-0 mr-1">
              {{ $t('balance.textStatus') }}:
            </p>
            <div
              class="status-product-link"
              :class="styleStatus(videoInfo.status)"
            >
              <span class="content-status">{{ statusProductLinkTxt(videoInfo.status) }}</span>
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="content-reason-reject">
        <p>{{ $t('partnerView.labelReasonsReject') }}:</p>
        <div
          class="reason-reject"
          v-html="videoInfo.rejectedReason"
        />
      </div>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        {{ $t('common.btnCancel') }}
      </b-button>
    </div>
    <!--/modal footer-->

    <video-modal
      ref="modal-play-video"
      :video-preview="urlVideo || null"
    />
  </b-modal>
</template>
<script>
/* eslint-disable import/no-extraneous-dependencies */

import {
  BModal,
  BButton,
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'

import {
  PROFILE_STATUS,
} from '@/constants'
import Ripple from 'vue-ripple-directive'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VideoModal from '@/views/product-links/components/VideoModal.vue'

export default {
  components: {
    VideoModal,
    BModal,
    BButton,
    BRow,
    BCol,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  props: {
    videoInfo: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      urlVideo: null,
    }
  },

  computed: {

    getNameVideo() {
      return videoUrl => {
        const urlFile = 'https://files.ecomdy.com/videos/'
        return videoUrl?.split(urlFile)[1]
      }
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },

    styleStatus() {
      const statusProductLink = {
        [PROFILE_STATUS.APPROVED]: 'approved-status',
        [PROFILE_STATUS.REVIEWING]: 'reviewing-status',
        [PROFILE_STATUS.REJECTED]: 'rejected-status',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found-status',
      }

      return status => statusProductLink[status]
    },

    statusProductLinkTxt() {
      const statusProductLink = {
        [PROFILE_STATUS.APPROVED]: this.$t('myAdAccountsPage.textApproved'),
        [PROFILE_STATUS.REVIEWING]: this.$t('productLinkPage.statusReviewing'),
        [PROFILE_STATUS.REJECTED]: this.$t('billingPage.statusRejected'),
        [PROFILE_STATUS.NOT_FOUND]: this.$t('productLinkPage.statusNotFound'),
      }

      return status => statusProductLink[status]
    },
  },

  methods: {
    hideModal() {
      this.$refs['modal-reason-reject-product-video'].hide()
    },

    showModal() {
      this.$refs['modal-reason-reject-product-video'].show()
    },

    openModalPlayVideo(video) {
      this.urlVideo = video
      this.$refs['modal-play-video'].showModal()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
.modal-body{
  padding: 24px;
}

.content-reason-reject{
  p{
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.info-product-video{
  display: flex;
  align-items: center;
  width: 100%;
}

.top-modal {
  margin-bottom: 32px;
}

.name-product-video{
  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2667ff;
  cursor: pointer;
}

.status-product-video{
  display: flex;
  align-items: center;
}

.status-product-link{
  text-transform:uppercase;
  margin: 0;
  border-radius: 100px;
  padding: 6px 10.5px;
  font-size: 12px;
  width: max-content;
  font-weight: 600;
  text-align: center;
}

.approved-status{
  background: #1DB9C31A;
  color: #1DB9C3;
}

.reviewing-status{
  background: #FBB03B1A;
  color: #FBB03B;
}

.rejected-status{
  background: #E11C4E1A;
  color: #E11C4E;
}

.label-reason{
  margin-bottom: 12px;
}

.reason-reject {
  width: 100%;
}

.content-reason-reject{
  margin-top: 1rem;

  .reason-reject{
    width: 100%;
    margin: 1rem 0 0;
    background: rgba(225, 28, 78, .1);
    border-radius: 20px;
    padding: 1rem;
    color: #E11C4E;
  }
}
</style>
