<template>
  <div>
    <list-product-links />
    <back-to-top />
  </div>
</template>
<script>
import BackToTop from './components/BackToTop.vue'
import ListProductLinks from './components/ListProductLinks.vue'

export default {
  components: { ListProductLinks, BackToTop },
}
</script>
<style lang=""></style>
