<template>
  <div>
    <btn-loading
      v-if="queryType === 'overview'"
      variant-convert="flat-secondary"
      class="mb-1"
      to="/"
    >
      <feather-icon
        size="16"
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      Continue with step guide
    </btn-loading>
    <div
      v-if="showListRoomMeeting"
      class="list-meeting bg-white mb-2"
    >
      <h4 class="font-medium mb-1">
        {{ $t('productLinkPage.scheduleCommunication') }}
      </h4>
      <div v-if="hasCountry">
        <div>
          <p
            v-if="hasUrlReviewing"
            class="mb-0 text-secondary font-14"
          >
            {{ $t('productLinkPage.requestProcessingMessage', { platformName }) }}
          </p>
          <p
            v-else
            class="mb-0 text-secondary font-14"
          >
            It look like your request is rejected, so that we can understand better on your business, you can arrange an
            appointment to speak with the {{ platformName }} experts below.
          </p>
        </div>
      </div>
      <div v-else>
        <p class="mb-0 text-secondary font-14">
          Your request is in processing, so that we can understand better on your business, you can
          <span
            class="text-blue underline cursor-pointer"
            @click="$router.push('/profile?type=product-link')"
          >
            update your information here.
          </span>
          Then arrange an appointment to speak with the {{ platformName }} experts.
        </p>
      </div>
    </div>
    <div class="header-table">
      <div class="form-action">
        <!-- filter desktop -->
        <div class="d-md-flex justify-content-between align-items-center mb-1">
          <div>
            <h4 class="font-medium mb-0">
              {{ $t('productLinkPage.titleTableProductLink') }}
            </h4>
          </div>
          <div class="d-flex align-items-center justify-content-end width-btn mt-1 mt-md-0">
            <a
              v-if="guideToSubmitProductLink"
              class="cursor-pointer text-green d-flex mr-50 align-items-center"
              :href="guideToSubmitProductLink"
              target="_blank"
            >
              {{ $t('productLinkPage.learn') }}
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-25"
              />
            </a>
            <btn-loading
              variant-convert="btn-submit"
              pill
              @click="openFormAddProductLinks"
            >
              {{ $t('productLinkPage.btnCreateNew') }}
            </btn-loading>
          </div>
        </div>

        <!-- / filter desktop -->
      </div>
    </div>
    <vue-good-table
      id="table-product-links"
      class="table-product-links"
      mode="remote"
      :columns="columns"
      :rows="listProductLink"
      :is-loading="loading"
      :pagination-options="{
        enabled: totalProductLinks > 0,
      }"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: productURL -->
        <div
          v-if="props.column.field === 'productURL'"
          class="d-flex align-items-center"
        >
          <div
            v-if="props.row.productUrl"
            class="content-url"
          >
            <span
              class="detail-url"
              @click.prevent="goToUrl(props.row.productUrl)"
            >{{ props.row.productUrl }}</span>
          </div>
        </div>

        <!-- Column: Target Market -->
        <div
          v-else-if="props.column.field === 'targetMarkets'"
          class="d-flex align-items-center"
        >
          <div v-if="props.row.targetMarkets">
            <ul class="list-target p-0 m-0">
              <li
                v-for="(item, index) in props.row.targetMarkets"
                :key="index"
                class="item-target"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>

        <!-- Column: Message -->
        <div
          v-else-if="props.column.field === 'rejectedReason'"
          class="d-flex align-items-center"
        >
          <div
            v-if="props.row.rejectedReason"
            class="w-100"
          >
            <div
              class="content-reason"
              v-html="props.row.rejectedReason"
            />
          </div>
          <div v-else>
            <span>N/A</span>
          </div>
        </div>

        <!-- Column: Status -->
        <div
          v-else-if="props.column.field === 'status'"
          class="d-flex align-items-center"
        >
          <div
            v-if="props.row.status"
            class="status-product-links"
            :class="statusVariantRejected(props.row.status)"
          >
            <span class="content-status">{{ statusTxt(props.row.status) }}</span>
          </div>
        </div>

        <!-- Column: Video -->
        <div
          v-else-if="props.column.field === 'video'"
        >
          <div
            v-if="getListVideoProduct(props.row)"
          >
            <div
              v-for="(video, index) in getListVideoProduct(props.row)"
              :key="index"
              class="list-url-video"
            >
              <div class="item-video">
                <p
                  class="video-url"
                  @click="showModalVideo(video.videoURL)"
                >
                  {{ video.videoURL }}
                </p>
              </div>
            </div>
          </div>
          <div v-else>
            N/A
          </div>
        </div>

        <!-- Column: Status video -->
        <div
          v-else-if="props.column.field === 'statusVideo'"
          class="d-flex align-items-center"
        >
          <div
            v-if="getListVideoProduct(props.row)"
          >
            <div
              v-for="(video, index) in getListVideoProduct(props.row)"
              :key="index"
              class="list-status"
            >
              <div
                class="status-product-links"
                :class="statusVariantRejected(statusVideo(video))"
              >
                <span class="content-status">{{ statusTxt(statusVideo(video)) }}</span>
                <feather-icon
                  v-if="statusVideo(video) === 'rejected'"
                  icon="EyeIcon"
                  size="21"
                  class="ic-show-reason-reject"
                  @click="showReasonReject(video)"
                />
              </div>
            </div>
          </div>
          <div v-else>
            N/A
          </div>
        </div>

        <!-- Column: actions -->
        <div
          v-else-if="props.column.field === 'actions'"
          class="d-flex align-items-center justify-content-center"
        >
          <div class="btn-control">
            <div
              v-if="isApprovedProductLink(props.row.status)"
              class="btn-upload-video cursor-pointer"
              @click="actionUploadVideo(props.row)"
            >
              <b-img
                :src="require('@/assets/images/icons/ic-upload-video.png')"
                alt="logo"
                class="ic-upload"
              />
              <p class="m-0 d-none d-md-block">
                {{ $t('productLinkPage.textUploadVideo') }}
              </p>
            </div>
          </div>
        </div>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalProductLinks > 0"
        slot="pagination-bottom"
      >
        <div class="footer-table">
          <span
            class="text-secondary font-14"
          >{{ totalProductLinks }} {{ $t('productLinkPage.textProductLink') }}</span>
        </div>

      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <!-- no account image -->
      <div
        slot="emptystate"
        class="text-center"
      >
        <no-product-links
          :img="require('@/assets/images/common/no-data-table.svg')"
          class="my-5"
        />
      </div>
    </vue-good-table>

    <video-modal
      ref="modal-play-video"
      :video-preview="urlVideo || null"
    />

    <info-reason-reject-product-video
      ref="modal-reason-reject-product-video"
      :video-info="videoSelected"
    />
  </div>
</template>
<script>
import {
  // BFormSelect,
  BSpinner,
  BImg,
  VBTooltip,
  // BPagination,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import { PROFILE_STATUS, AU_MARKETS } from '@/constants'
import { VueGoodTable } from 'vue-good-table'
import { createNamespacedHelpers } from 'vuex'
import billingMixin from '@/mixins/billingMixin'
import envMixin from '@/mixins/envMixin'
import _find from 'lodash/find'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import InfoReasonRejectProductVideo from '@/views/product-links/components/infoReasonRejectProductVideo.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import NoProductLinks from './NoProductLinks.vue'
import VideoModal from './VideoModal.vue'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
    InfoReasonRejectProductVideo,
    // BFormSelect,
    BSpinner,
    BImg,
    // BPagination,
    // vSelect,
    VueGoodTable,
    NoProductLinks,
    VideoModal,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [billingMixin, stepGuideMixin, envMixin],

  data() {
    return {
      isScrollTable: false,
      currentPage: 1,
      searchTerm: '',

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      urlVideo: null,
      videoSelected: {},
    }
  },

  computed: {
    ...mapGetters(['user', 'status', 'loading', 'message', 'isTikTokAppCenter']),
    columns() {
      return [
        {
          label: this.$t('productLinkPage.titleColumnProductUrl'),
          field: 'productURL',
          thClass: 'border-0 header-table-mf th-product-link',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-product-url padding-td',
        },
        {
          label: this.$t('productLinkPage.titleColumnTargetMarkets'),
          field: 'targetMarkets',
          thClass: 'border-0 header-table-mf th-product-link',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-target-markets padding-td',
        },
        {
          label: this.$t('productLinkPage.titleColumnReason'),
          field: 'rejectedReason',
          thClass: 'border-0 header-table-mf th-product-link',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-reason-reject padding-td reason-product-link',
        },
        {
          label: this.$t('balance.textStatus'),
          field: 'status',
          thClass: 'border-0 header-table-mf th-product-link',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-status padding-td',
        },
        {
          label: this.$t('productLinkPage.textProductVideo'),
          field: 'video',
          thClass: 'border-0 header-table-mf th-product-link',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-product-video padding-td',
          hidden: !this.isExclusivePartner,
        },
        {
          label: this.$t('productLinkPage.statusVideo'),
          field: 'statusVideo',
          thClass: 'border-0 header-table-mf th-product-link',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-status padding-td',
          hidden: !this.isExclusivePartner,
        },
        {
          label: this.$t('common.textActions'),
          field: 'actions',
          thClass: `p-1 border-0 header-table-mf action-column ${this.isScrollTable ? 'scroll-action-column' : ''}`,
          tdClass: `p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-column-actions action-column bg-white ${this.isScrollTable ? 'scroll-action-column' : ''}`,
          sortable: false,
          hidden: !this.isExclusivePartner,
        },
      ]
    },

    totalProductLinks() {
      return this.listProductLink?.length
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },

    isApprovedProductLink() {
      return status => status !== PROFILE_STATUS.REJECTED
    },

    statusVariantRejected() {
      const statusColor = {
        [PROFILE_STATUS.APPROVED]: 'approved',
        [PROFILE_STATUS.REJECTED]: 'rejected',
        [PROFILE_STATUS.REVIEWING]: 'reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found',
      }

      return status => statusColor[status]
    },

    statusTxt() {
      const statusContent = {
        [PROFILE_STATUS.APPROVED]: this.$t('myAdAccountsPage.textApproved'),
        [PROFILE_STATUS.REJECTED]: this.$t('billingPage.statusRejected'),
        [PROFILE_STATUS.REVIEWING]: this.$t('productLinkPage.statusReviewing'),
        [PROFILE_STATUS.NOT_FOUND]: this.$t('productLinkPage.statusNotFound'),
      }

      return status => statusContent[status]
    },

    queryType() {
      return this.$route.query.type
    },

    hasUrlApproved() {
      return !!_find(this.listProductLink, productURL => productURL.status === PROFILE_STATUS.APPROVED)
    },

    showListRoomMeeting() {
      return (this.listProductLink?.length > 0 && !this.hasUrlApproved && !this.hasKyc && this.isEcomdyPlatform)
    },

    hasUrlReviewing() {
      return !!_find(this.listProductLink, productURL => productURL.status === PROFILE_STATUS.REVIEWING)
    },

    hasCountry() {
      return this.user?.data?.country
    },

    hasKyc() {
      return this.user?.data?.kyc
    },

    checkMarket() {
      const userCountry = this.user?.data?.country
      return AU_MARKETS.countries.includes(userCountry)
    },

    getListVideoProduct() {
      return item => item?.summaryVideos?.videos
    },

    statusVideo() {
      return itemVideo => itemVideo?.status || null
    },
  },

  watch: {
    listProductLink: {
      async handler() {
        const table = await document.querySelector('#table-product-links .vgt-responsive')
        if (table?.scrollWidth > table?.clientWidth) {
          this.isScrollTable = true
        } else {
          this.isScrollTable = false
        }
      },
    },
  },

  created() {
    this.initScrollListProductLinkTable()
  },

  methods: {
    async initScrollListProductLinkTable() {
      await document.querySelector('#table-product-links')
      document.querySelector('#table-product-links .vgt-responsive').addEventListener('scroll', event => this.scrollListProductLinkTable(event))
    },

    scrollListProductLinkTable(event) {
      const maxScroll = event.target.scrollWidth - event.target.clientWidth
      const currentScroll = Math.floor(event.target.scrollLeft)
      if (currentScroll === maxScroll) {
        this.isScrollTable = false
      } else {
        this.isScrollTable = true
      }
    },

    async scrollHandler() {
      const table = await document.querySelector('#table-product-links .vgt-responsive')
      if (table?.scrollWidth > table?.clientWidth) {
        this.isScrollTable = true
      } else {
        this.isScrollTable = false
      }
    },

    showReasonReject(videoInfo) {
      this.videoSelected = videoInfo
      this.$refs['modal-reason-reject-product-video'].showModal()
    },

    showModalVideo(urlVideo) {
      this.urlVideo = urlVideo
      this.$refs['modal-play-video'].showModal()
    },

    actionUploadVideo(productData) {
      const { productUrl } = productData
      this.$router.push({
        name: 'add-product-link',
        query: { url: productUrl },
      })
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
    },

    openFormAddProductLinks() {
      if (this.noPlan) {
        if (this.isTikTokAppCenter) {
          const idToken = localStorage.getItem('id_token')
          window.open(`${process.env.VUE_APP_SITE_URL}/billing?id_token=${idToken}`)
        } else {
          this.$router.push('/billing')
        }
      } else {
        this.$router.push({ name: 'add-product-link' })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.table-product-links {
  .vgt-table {
    border: none;
  }

  .th-product-link {
    padding: 9px 24px;
  }
}

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.badge {
  border-radius: 100px;
  padding: 6px 12px;
  font-weight: 600;
}

.header-table {
  background-color: #fff;
  border-top-left-radius: var(--border-radius-base);
  border-top-right-radius: var(--border-radius-base);
  padding: 20px 24px;
}

.footer-table {
  padding: 10px 24px 25px 24px;
  background-color: #fff;
  border-bottom-left-radius: var(--border-radius-base);
  border-bottom-right-radius: var(--border-radius-base);
}

.td-product-url {
  min-width: 200px;
  max-width: 350px;
}

.td-target-markets {
  max-width: 150px;
}

.td-reason-reject {
  min-width: 200px;
  width: max-content;
  max-width: 300px;
}

.td-column-actions {
  width: max-content;

  @media(min-width: 768px) {
    min-width: 200px !important;
  }
}

.td-product-video{
  max-width: 300px;
  width: 100%;
}

.td-status {
  min-width: 200px !important;
  width: max-content;
}

.padding-td {
  padding: 28px 24px !important;
}

.content-reason {
  hyphens: auto;
  word-wrap: break-word;

  p {
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
.width-btn {
  width: 100%;

  @media (min-width: 768px) {
    max-width: max-content;
  }
}

.content-url {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .detail-url {
    color: #2667ff;
    text-decoration: underline;
    cursor: pointer;
  }
}

.list-target {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  .item-target {
    padding: 6px 8px;
    background-color: #16213e0d;
    border-radius: 4px;
    margin: .5rem;
  }
}

.status-product-links {
  padding: 5px 12px;
  border-radius: 100px;
  text-align: center;
  position: relative;

  .ic-show-reason-reject{
    position: absolute;
    top: -10px;
    right: -10px;
    cursor: pointer;
    color: #000;
  }

  .content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}

.list-meeting {
  border-radius: var(--border-radius-base);
  padding: 20px 24px;
}

.btn-upload-video {
  display: flex;
  justify-content: center;
  align-items: center;

  @media(min-width: 768px) {
    border-radius: 8px;
    border: 1px solid #16213e;
    padding: 0.486rem 1rem;
  }

  .ic-upload {
    width: 24px;
    height: 24px;
  }
}
.list-url-video{

  .item-video{
    height: 30px;
    display: flex;
    align-items: center;
  }

  &:not(:last-child){
    .item-video{
      margin-bottom: 1rem;
    }
  }
}
.video-url{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  color: #2667ff;
  margin: 0;

  &:hover{
    text-decoration: underline;
  }
}

.list-status{
  &:not(:last-child){
    margin-bottom: 1rem;
  }
}

.video-content {
  position: relative;
  width: 120px;
  height: 75px;

  .video-list {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .icon-play-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon-play{
      z-index: 999;
      color: #fff;
    }

    &.show-icon {
      animation: showicon 0.5s;
    }

    &.hide-icon {
      visibility: hidden;
      opacity: 0;
    }

    @keyframes showicon {
      0%{
        visibility: hidden;
        opacity: 0;
      }
      100%{
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
</style>
